import React from "react";

const Home=()=>{
    return(
        <>
        
<body class="full-screen-preview">
    
    <iframe class="full-screen-preview__frame" src="https://demo.themeies.com/elearning-react/" name="preview-frame" frameborder="0" noresize="noresize" data-view="fullScreenPreview" allow="geolocation 'self'; autoplay 'self'">
</iframe>


    {/* <script nonce="phyxzP+M+0Qnkflg/gwBTQ==">
        //<![CDATA[
        $(function() {
            viewloader.execute(Views);
        });

        //]]>
    </script> */}
    {/* <script nonce="phyxzP+M+0Qnkflg/gwBTQ==">
        //<![CDATA[
        (function() {
            function normalizeAttributeValue(value) {
                if (value === undefined || value === null) return undefined

                var normalizedValue

                if (Array.isArray(value)) {
                    normalizedValue = normalizedValue || value
                        .map(normalizeAttributeValue)
                        .filter(Boolean)
                        .join(', ')
                }

                normalizedValue = normalizedValue || value
                    .toString()
                    .toLowerCase()
                    .trim()
                    .replace(/&amp;/g, '&')
                    .replace(/&#39;/g, "'")
                    .replace(/\s+/g, ' ')

                if (normalizedValue === '') return undefined
                return normalizedValue
            }

            var pageAttributes = {
                app_name: normalizeAttributeValue('Marketplace'),
                app_env: normalizeAttributeValue('production'),
                app_version: normalizeAttributeValue('42cd4065cae9786d14a9734b615e1016e3f2c6c5'),
                page_type: normalizeAttributeValue('item'),
                ga_param: normalizeAttributeValue(''),
                event_attributes: null,
                user_attributes: {
                    user_id: normalizeAttributeValue(''),
                    market_user_id: normalizeAttributeValue(''),
                }
            }
            dataLayer.push(pageAttributes)

            dataLayer.push({
                event: 'analytics_ready',
                event_attributes: {
                    event_type: 'user',
                    custom_timestamp: Date.now()
                }
            })
        })();

        //]]>
    </script> */}
    {/* <noscript>
      <iframe
        src="https://www.googletagmanager.com/ns.html?id=GTM-W8KL5Q5"
        height="0"
        width="0"
        style="display:none;visibility:hidden"
      >
      </iframe>
    </noscript> */}



    <div id="affiliate-tracker" class="is-hidden" data-view="affiliatesTracker" data-cookiebot-enabled="true"></div>

    {/* <script src="https://consent.cookiebot.com/uc.js" data-cbid="58b7468f-7dba-4036-baad-925e721641e5" async="async" id="Cookiebot" nonce="phyxzP+M+0Qnkflg/gwBTQ=="></script>


    <script src="https://public-assets.envato-static.com/assets/market/core/standard_measurements-3bc54d0cbac28acaf9e49915490168f52bb9b8bea5d35f6edabb67a686a83af4.js" nonce="phyxzP+M+0Qnkflg/gwBTQ=="></script> */}






</body>
        </>
    );
}
export default Home;