import './App.css';
import ReactDOM from 'react-dom/client';
import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './User/AllPages/Home';

export default function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          {/*   ===========   NavBar Page   =========== */}
          <Route path="/" element={<Home/>} />



        </Routes>
      </BrowserRouter>
    </>
  );
}